import React from 'react';

const Section4 = React.forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <div ref={ref} className="bg-[#f9f9f9]">
      <div className="xs w-full max-w-[1148px] mx-auto py-[65px] sm:py-[90px] px-5 lg:px-2 xl:px-0">
        <div className="font-p-bolder text-[1.625rem] sm:text-[2.5rem] text-black leading-9 mb-7 sm:mb-[2.75rem] text-center sm:text-left">
          Partners
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6 sm:gap-7 w-full">
          <div className="flex flex-col items-center col-span-1 space-y-2 text-center mb-1 sm:mb-5">
            <img src="/images/bluepoint.svg" alt="1" />
            <span className="block text-[#8893C1] text-[0.938rem] sm:text-[1.125rem]">
              블루포인트 파트너스
              <br />
              투자사
            </span>
          </div>
          <div className="flex flex-col items-center col-span-1 space-y-2 text-center mb-1 sm:mb-5">
            <img src="/images/tipskorea.svg" alt="1" />
            <span className="block text-[#8893C1] text-[0.938rem] sm:text-[1.125rem]">
              기술창업프로그램
              <br />
              TIPS 선정
            </span>
          </div>
          <div className="flex flex-col items-center col-span-1 space-y-2 text-center mb-1 sm:mb-5">
            <img src="/images/wisebirds.svg" alt="1" />
            <span className="block text-[#8893C1] text-[0.938rem] sm:text-[1.125rem]">
              와이즈버즈
              <br />
              마케팅 파트너
            </span>
          </div>
          <div className="flex flex-col items-center col-span-1 space-y-2 text-center mb-1 sm:mb-5">
            <img src="/images/sakak.svg" alt="1" />
            <span className="block text-[#8893C1] text-[0.938rem] sm:text-[1.125rem]">
              사각
              <br />
              기술파트너
            </span>
          </div>
          <div className="flex flex-col items-center col-span-1 space-y-2 text-center mb-1 sm:mb-5">
            <img src="/images/genesisitlab.svg" alt="1" />
            <span className="block text-[#8893C1] text-[0.938rem] sm:text-[1.125rem]">
              제네시스IT랩
              <br />
              기술파트너
            </span>
          </div>
        </div>
      </div>
    </div>
  );
});

export { Section4 };
