const Footer = () => {
  return (
    <div className="bg-[#111537]">
      <div
        className="xs w-full max-w-[1148px] mx-auto pt-[37px] pb-[115px] px-5 lg:px-2 xl:px-0 items-center justify-center md:justify-start flex flex-wrap md:flex-nowrap">
        <a href="/"><img src="/images/footerlogo.svg" height="48" width="94" alt="Logo" /></a>
        <div className="w-full md:w-[80%] md:pl-4 pl-0 text-center md:text-left mt-5 md:mt-0">
          <p className="text-[#ccc] text-[1rem]">1Self.World </p>
          <div className="flex w-full justify-center md:justify-start">
            <p className="text-[#ccc] text-[1rem] hidden sm:block">서울시 강남구 도산대로 317 11층 원셀프월드(주) | EMAIL :
              <a href="mailto:contact@1self.world" className="pl-2">contact@1self.world</a>
            </p>
            <p className="text-[#ccc] text-[0.938rem] sm:text-[1rem] block sm:hidden">서울시 강남구 도산대로 317 11층 원셀프월드(주) <br /> EMAIL :
              <a href="mailto:contact@1self.world" className="pl-2">contact@1self.world</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Footer };