import React from 'react';

const OrderedList: React.FC<{ children: React.ReactNode, type?: 'a' | 'i' | '1' }> = ({ children, type }) => {
  const typeMap = {
    i: 'list-[lower-roman]',
    a: 'list-[lower-alpha]',
    1: 'list-decimal'
  }

  return <ol className={`list-outside pl-7 py-2 ${typeMap[type ?? 'a']}`}>{children}</ol>;
};

export { OrderedList };