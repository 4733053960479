const Section1 = () => {
  return (
    <div
      className="sm:bg-[url('/public/images/main-bg.webp')] bg-[url('/public/images/main-bg-mobile.webp')] bg-center bg-cover sm:h-[800px] h-[560px] w-full flex justify-center">
      <div className="w-[600px] h-full sm:flex hidden flex-col justify-start items-center pt-[112px]">
        <div className="font-p-bold text-white max-w-[37rem] leading-9 mb-2 flex flex-col text-[1.625rem] text-center">
          <span>1Self.World는 쉽고 편리한 블록체인 지갑 기반의</span>
          <span>리워드앱을 만드는 <span className='text-[#4784FF]'>Web3.0 스타트업</span>입니다.</span>
        </div>
        <div className="sm:text-[1rem] text-[#BFCEEC] max-w-[301px] sm:max-w-[484px] leading-5 sm:leading-6 flex flex-col text-center">
          <span>블록체인에 대한 이해 없이도 누구나 쉽게 이용할 수 있는 서비스입니다.</span>
          <span>매일 방문하여 간단한 이벤트에 참여하면 보상을 받을 수 있는 리워드앱입니다.</span>
          <span>이벤트 참여 결과는 뱃지로 발행됩니다.</span>
        </div>
        <div className="font-medium text-[1rem] text-[#A7B6D3] text-left flex flex-col mt-12 self-end">
          <span>뱃지는</span>
          <span>SBT(SoulBound Token)으로</span>
          <span>블록체인에 기록됩니다.</span>
        </div>
      </div>
      <div className="sm:hidden flex flex-col justify-start items-center pt-20">
        <div className="font-p-bold text-white mb-2 flex flex-col text-[1.125rem] max-w-[336px] text-center leading-6">
          <span>1Self.World는</span>
          <span>쉽고 편리한 블록체인 지갑 기반의 리워드앱을</span>
          <span>만드는 <span className="text-[#4784FF]">Web3.0 스타트업</span>입니다.</span>
        </div>
        <div
          className="text-[0.8rem] sm:text-[1rem] text-[#BFCEEC] max-w-[302px] sm:max-w-[484px] leading-5 sm:leading-6 text-center flex flex-col">
          <span>블록체인에 대한 이해 없이도 누구나 쉽게 이용할 수 있는</span>
          <span>서비스입니다. 매일 방문하여 간단한 이벤트에 참여하면</span>
          <span>보상을 받을 수 있는 리워드앱입니다.</span>
          <span>이벤트 참여 결과는 뱃지로 발행됩니다.</span>
        </div>
        <div className="font-medium text-[0.8rem] text-[#A7B6D3] text-center flex flex-col mt-[210px]">
          <span>뱃지는 SBT(SoulBound Token)으로</span>
          <span>블록체인에 기록됩니다.</span>
        </div>
      </div>
    </div>
  )
}

export { Section1 }