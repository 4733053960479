import React from 'react';
import { Title } from './components/title';
import { Content } from './components/content';
import { OrderedList } from './components/ordered-list';

const TermConditions = () => {
  return <div className="max-w-[1140px] m-auto w-full px-6 py-10 lg:px-2 xl:px-0">
    <Title>1. 목적</Title>
    <Content className="pt-4 pb-2">이 약관은 원셀프월드 주식회사(이하 “회사”)와, 회사의 모바일 어플리케이션 ‘MyB’ 를 통하여 제공되는 온라인 서비스(이하 “서비스”)를 이용하는 이용자 간의 권리와 의무, 책임사항을 규정하는 것을 목적으로 합니다.</Content>

    <Title>2. 용어의 정의</Title>
    <Content>
      <OrderedList type="a">
        <li>이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</li>
        <OrderedList type="i">
          <li>“이용자” : 서비스를 이용하는 14세 이상의 개인</li>
          <li>“서비스” : 회사의 모바일 어플리케이션 ‘MyB’ 를 통하여 이용자에게 제공되는 콘텐츠, 이벤트 참여, 리워드 지급 관련 온라인 서비스.</li>
          <li>
            “리워드” : 이용자가 회사의 서비스내에 사전에 공지한 절차와 방식, 조건에 따라 서비스를 내 세부 기능을 이용하고 일정 조건을 충족시키는 등의 활동을 완료하면 수령할 수 있는 포인트 또는
            이와 유사한 것으로써, 서비스 내에서 제공되는 리워드샵에서 정해진 조건에 따라 쿠폰 등으로 교환이 가능한 것.
          </li>
          <li>
            “비밀번호” : 이용자가 서비스에 로그인 또는 서비스 시작을 위하여 입력하여야 하는 인증암호(간편비밀번호, 생체인증수단의 인증정보 등으로써, 이용자의 기기에 저장되는 인증정보도 포함합니다)를
            의미합니다.
          </li>
        </OrderedList>
        <li>본 조에서 정의되지 않은 이 약관상의 용어는 관련법령에 의합니다.</li>
      </OrderedList>
    </Content>

    <Title>3. 약관의 명시, 효력 및 개정</Title>
    <Content>
      <OrderedList type="a">
        <li>회사는 이 약관을 이용자가 서비스 이용 시 처음 보게 되는 초기화면 또는 초기화면에 연결되어 있는 화면 (이하 연결화면을 포함합니다)에 게시합니다.</li>
        <li>회사는 「약관의 규제에 관한 법률」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 등 관련법령을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.</li>
        <li>회사가 이 약관을 개정하는 경우에는 개정된 약관의 적용일자 및 개정사유를 명시하여 현행 약관과 함께 그 적용일자 7일(다만, 이용자에게 불리한 내용으로 변경하는 경우에는 30일) 이전부터 적용일자 전일까지 공지 또는 통지합니다. 회사는 이용자에게 불리한 내용으로 개정할 경우에는 제1항 외에 서비스 내 전자우편, 로그인 시 동의 요구 팝업창 등의 전자적 수단을 통하여 따로 명확히 통지하도록 합니다.</li>
        <li>개정된 약관은 법령에 특별한 규정 또는 기타 부득이한 사유가 없는 한 그 적용일자 이전으로 소급하여 적용되지 아니합니다.</li>
        <li>회사가 제3항에 따라 개정 약관을 공지 또는 통지하면서 이용자에게 제3항에서 정한 적용일자까지의 기간 이내에 의사 표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 고지 하였음에도 이용자가 명시적으로 거부의 의사를 표시하지 아니하거나 이용계약을 해지하지 않는 경우 이용자가 개정 약관에 동의한 것으로 봅니다.</li>
      </OrderedList>
    </Content>

    <Title>4. 이용계약의 성립</Title>
    <Content>
      <OrderedList type="a">
        <li>서비스를 이용하고자 하는 자가 어플리케이션을 설치하고 실행시켜 서비스 이용을 시작함으로써, 이용자는 이 약관에서 정하는 바에 따라 서비스를 이용하는 내용의 계약(“이용계약”)이 성립합니다. 회사는 이용승낙의 의사표시를 서비스 내 화면에 게시하거나 기타 방법으로 통지합니다.</li>
        <li>서비스를 이용하고자 하는 자는 이 약관에 동의하고, 회사가 정하는 서비스 이용 신청 방식에 따라 필요한 사항을 회사에 제공합니다.</li>
        <li>회사는 다음과 같은 사유가 발생한 경우 이용신청에 대한 승낙을 거부하거나 유보할 수 있습니다.</li>
        <OrderedList type="i">
          <li>서비스를 이용하고자 하는 자가 실명인증을 완료하지 못했거나 비실명으로 이용신청 하는 경우.</li>
          <li>2개 이상의 단말기기를 통하여 서비스를 이용하려는 경우.</li>
          <li>서비스를 이용하고자 하는 자가 만 14세 이상의 개인이 아닌 경우.</li>
          <li>이미 이용계약이 체결된 이용자가 이용신청을 하는 경우.</li>
          <li>회사로부터 서비스 이용과 관련된 제한 조치(예 : 일정 기간 이용 제한) 등을 받은 이용자가 그 조치기간 중에 이용계약을 임의 해지하고 재이용신청을 하는 경우.</li>
          <li>회사의 인적, 물적 설비에 여유가 없거나 기술상 지장이 있는 경우.</li>
          <li>기타 이 약관에 위배되거나 위법 또는 부당한 이용신청임이 확인된 경우 및 회사가 합리적인 판단에 의하여 필요하다고 인정하는 경우.</li>
        </OrderedList>
      </OrderedList>
    </Content>

    <Title>5. 인증수단의 관리</Title>
    <Content className="pt-4 pb-2">서비스 이용 기기와 비밀번호 등 서비스 이용을 위한 인증수단에 대한 관리책임은 이용자에게 있으며, 이용자는 어떠한 경우에도 비밀번호를 타인에게 양도하거나 대여할 수 없습니다. 회사의 귀책사유 없이 이용자의 기기 또는 비밀번호의 유출, 양도, 대여로 인하여 발생하는 손실이나 손해에 대하여는 이용자 본인이 그에 대한 책임을 부담합니다.</Content>

    <Title>6. 이용계약의 종료</Title>
    <Content>
      <OrderedList type="a">
        <li>이용계약의 해지</li>
        <OrderedList type="i">
          <li>이용자는 언제든지 서비스 어플리케이션을 삭제함으로써 이용계약을 해지(종료)할 수 있습니다.</li>
          <li>이용자는 이용계약을 해지함으로써 발생한 불이익에 대한 책임은 이용자 본인이 부담하여야 하며, 이용계약이 종료되면 회사는 이용자에게 부가적으로 제공한 각종 혜택(리워드를 포함)을 회수할 수 있습니다.</li>
        </OrderedList>
        <li>회사의 해지</li>
        <OrderedList type="i">
          <li>회사는 다음과 같은 사유가 발생하거나 확인된 경우 이용계약을 해지할 수 있습니다.</li>
          <OrderedList type="1">
            <li>타인의 권리나 명예, 신용 기타 정당한 이익을 침해하거나 법령 또는 공서양속에 위배되는 행위를 한 경우</li>
            <li>회사가 제공하는 서비스의 원활한 진행을 방해하는 행위를 하거나 시도한 경우</li>
            <li>제4조 제3항의 승낙거부사유가 있음이 확인된 경우</li>
            <li>이용자가 이 약관에 위배되는 행위를 하거나 이 약관에서 정한 해지사유가 발생한 경우</li>
          </OrderedList>
          <li>회사가 해지를 하는 경우 회사는 이용자에게 서비스 내 팝업 등을 통하여 해지사유를 밝혀 해지의사를 통지합니다. 이용계약은 회사의 해지의사가 이용자에게 도달한 때에 종료됩니다.</li>
        </OrderedList>
        <li>본 조 제2항 1호에서 정한 이용계약 해지사유가 발생하였거나 그러한 해지사유로 인하여 이용계약이 해지된 경우 회사는 이용자에게 부가적으로 제공한 각종 혜택(리워드를 포함)을 회수할 수 있습니다.</li>
        <li>본 항에서 정한 바에 따라 이용계약이 종료된 경우에는, 이용자의 재이용신청에 대하여 회사는 이에 대한 승낙을 거절할 수 있습니다.</li>
      </OrderedList>
    </Content>

    <Title>7. 서비스 제공 및 리워드</Title>
    <Content>
      <OrderedList type="a">
        <li>회사는 서비스를 통하여 이용자에게 광고 및 홍보 관련 컨텐츠를 제공하고, 이용자는 컨텐츠를 열람 및 이용하고, 서비스를 통하여 제안되는 각종 활동에 참여합니다. 이러한 제공되는 컨텐츠의 종류와 형태는 회사가 사전에 공지한 절차와 방식, 조건에 따라 상이할 수 있습니다.</li>
        <li>회사는 필요한 경우 서비스 혹은 그 일부에 대하여, 미리 공지한 후 일시적 또는 영구적으로 그러한 서비스의 제공을 수정하거나 중단할 수 있습니다.</li>
        <li>회사는 사전에 공지한 절차와 방식, 조건에 따라, 이용자가 컨텐츠 이용 및 활동 참여를 할 경우 리워드를 지급할 수 있습니다.  리워드는 서비스 내 컨텐츠 이용 및 활동 참여를 통하여 이용자에게 무상으로 지급되는 것으로써, 유효기간과 사용조건은 회사가 서비스를 통해 별도로 모바일 어플리케이션에서 표시한 바에 의합니다.</li>
        <li>이용자가 보유한 리워드는 서비스가 제공하는 리워드 샵 또는 리워드 사용 제안 등을 통하여 쿠폰 등으로 교환할 수 있습니다. 이용자가 리워드를 사용하기 위해서는 본인 인증 등 회사가 정한 절차를 거쳐야 합니다. 회사는 이용자가 보유한 리워드를 사용할 수 있는 리워드 샵의 내용이나 세부 사용조건을 변경할 수 있습니다.</li>
        <li>리워드 사용에 관하여 회사가 별도로 공지한 유효기간과 사용조건이 충족되지 않거나, 이용계약 해지 등으로 인하여 리워드가 소멸되면, 이용자는 해당 리워드를 사용할 수 없으며, 또한 유효기간이 만료된 리워드는 자동으로 소멸됩니다. 이용자가 보유한 리워드는 현금으로 환불되지 않습니다.</li>
      </OrderedList>
    </Content>
    <Title>8. 광고의 게재</Title>
    <Content>
      <OrderedList type="a">
        <li>회사는 서비스의 운영과 관련하여 서비스 내 화면 등에 광고를 게재할 수 있습니다</li>
      </OrderedList>
    </Content>

    <Title>9. 저작물 책임</Title>
    <Content>
      <OrderedList type="a">
        <li>이용자가 회사가 제공하는 서비스를 이용하면서 작성한 댓글, 메시지 등 각종 게시물과 메시지(“이용자 저작물”)의 저작권은 이를 작성한 이용자 본인에게 있으며, 해당 게시물이 타인의 저작권을 침해하는 경우 그에 대한 책임은 이용자 본인이 부담합니다.</li>
        <li>회사는 이용자 저작물을 서비스의 분석, 개선, 개발, 운영 등을 위하여 자유롭게 사용, 복제, 배포, 전송, 전시, 이용, 수정, 편집 등을 할 수 있으며, 회사가 아닌 타 사업자의 웹사이트 등에 복제, 배포, 전송, 전시할 수 있습니다.</li>
        <li>회사는 이용자 저작물이 다음 각 호에 해당하는 경우 해당 이용자 저작물을 삭제하거나 게시자에 대하여 특정 서비스의 이용제한, 이용계약의 해지 등의 조치를 할 수 있습니다.</li>
        <OrderedList type="i">
          <li>법령을 위반하는 내용을 포함하는 경우</li>
          <li>타인의 권리나 명예, 신용 기타 정당한 이익을 침해하는 경우</li>
          <li>정보통신기기의 오작동을 일으킬 수 있는 악성코드나 데이터를 포함하는 경우</li>
          <li>사회 공공질서나 미풍양속에 위배되는 경우</li>
          <li>회사가 제공하는 서비스의 원활한 진행을 방해하는 경우</li>
          <li>회사의 동의 없이 회사 또는 서비스에서 의도하지 않은 상품이나 용역을 홍보하는 경우</li>
          <li>범죄행위와 관련된 내용을 포함하는 경우</li>
          <li>정치, 경제적 분쟁을 야기하는 내용을 포함하는 경우</li>
        </OrderedList>
        <li>서비스 내에서 회사가 작성한 저작물에 대한 저작권 및 기타 지식재산권은 회사에 귀속합니다. 회사는 이용자에게 회사가 정한 이용조건에 따라 모바일 어플리케이션, 콘텐츠 등을 이용할 수 있는 이용권한만을 부여합니다.  이용자는 서비스를 이용함으로써 얻은 저작물, 콘텐츠, 소프트웨어, 기타 지적재산을 회사의 사전 승낙 없이 복제, 송신, 전송, 출판, 배포, 방송 등 방법에 의하여 이용하거나 제3자에게 이용하게 하여서는 안 됩니다.</li>
      </OrderedList>
    </Content>

    <Title>10. 금지행위</Title>
    <Content>
      <OrderedList type="a">
        <li>이용자는 서비스의 이용과 관련하여 다음과 같은 행위를 하여서는 안 됩니다.</li>
        <OrderedList type="i">
          <li>서비스 내 컨텐츠나 이벤트가 의도하지 않은 방식으로 리워드 지급 조건을 충족시켜 리워드를 수령하는 행위</li>
          <li>회사 소유의 서비스 및 저작물 외부유출 금지</li>
          <li>다른 사용자의 저작물 및 개인정보 외부 유출 금지</li>
          <li>제 3자의 명예훼손, 욕설, 정크/스팸 등의 게시 금지</li>
          <li>부정적 방법으로 리워드를 획득하는 등 서비스 정책에 반하는 행위 금지</li>
        </OrderedList>
        <li>이용자가 본조 제1항의 금지행위를 한 경우, 회사는 이용자에게 부가적으로 제공한 각종 혜택(리워드를 포함)을 회수할 수 있으며, 회사는 본 약관에 따라 이용계약을 해지하고, 손해배상을 청구할 수 있습니다.</li>
      </OrderedList>
    </Content>

    <Title>11. 회사의 면책</Title>
    <Content>
      <OrderedList type="a">
        <li>회사는 외부 광고주로부터 의뢰받아 서비스 내에 컨텐츠 및 이벤트를 게시하고 이용자의 참여를 유도합니다. 회사는 서비스 내의 컨텐츠 및 이벤트 등에 게시된 외부 광고주의 상품 및 용역, 브랜드 등과 관련하여 그 품질, 완전성, 안전성, 적법성, 진실성 및 타인의 권리에 대한 비침해성 등 일체에 대해서 보증하지 아니합니다. 또한 회사는 그러한 외부 광고주를 대리하지 아니합니다.</li>
        <li>회사는 이용자의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않습니다.</li>
        <li>회사는 이용자가 보유한 리워드의 가치 변동 또는 사용 조건 변동 대하여 책임을 지지 않습니다.</li>
        <li>회사는 이용자가 자신의 개인정보 또는 비밀번호와 같은 인증정보를 타인에게 유출 또는 제공함으로써 발생하는 피해에 대해서 책임을 지지 않습니다.</li>
        <li>회사는 컴퓨터 등 정보통신설비의 보수, 점검, 교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있으며, 이와 관련하여 회사의 고의 또는 과실이 없는 한 이용자 또는 제3자에게 발생한 손해에 대하여 책임지지 않습니다.</li>
        <li>회사는 천재지변, 전쟁, 화재, 홍수, 역병, 자연재해, 정부의 조치 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스의 제공을 제한하거나 일시 중단할 수 있으며, 이와 관련하여 회사의 고의 또는 과실이 없는 한 이용자 또는 제3자에게 발생한 손해에 대하여 책임지지 않습니다.</li>
      </OrderedList>
    </Content>

    <Title>12. 손해배상책임</Title>
    <Content>
      <OrderedList type="a">
        <li>회사 또는 이용자가 이 약관에 따라 이행되어야 할 의무를 위반하거나 이행하지 아니하는 경우, 위반 당사자는 그로 인하여 상대방 당사자가 입은 모든 손해를 배상하여야 합니다.</li>
        <li>본 조에 따른 손해배상의 청구는 이 약관에 따른 권리 행사 및 관련 계약에 따른 권리 행사에 영향을 미치지 아니합니다.</li>
      </OrderedList>
    </Content>

    <Title>13. 재판관할 및 준거법</Title>
    <Content>
      <OrderedList type="a">
        <li>회사와 이용자 사이에 서비스 및 이 약관과 관련한 제반 분쟁 및 소송은 민사소송법 등 관계 법령에 따른 관할법원을 제1심 관할법원으로 합니다</li>
        <li>회사와 이용자 사이에 제기된 소송에는 대한민국법을 적용합니다.</li>
      </OrderedList>
    </Content>

    <Title>14. 기타</Title>
    <Content className="pt-4 pb-2">이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 관련 법령에 따릅니다.</Content>

    <Title>부칙</Title>
    <Content>제1조 (적용일자) 이 약관은 2024. 04. 01. 부터 적용됩니다.</Content>
  </div>;
};

export { TermConditions }