import React from 'react';

type SidebarProps = {
  toggleSidebar: () => void;
  scrollToSection: (ref: React.RefObject<HTMLDivElement>) => void;
  section2Ref: React.RefObject<HTMLDivElement>;
  section3Ref: React.RefObject<HTMLDivElement>;
  section4Ref: React.RefObject<HTMLDivElement>;
}

const Sidebar: React.FC<SidebarProps> = ({
                                           toggleSidebar,
                                           scrollToSection,
                                           section2Ref,
                                           section3Ref,
                                           section4Ref,
                                         }) => {
  return (
    <div className="fixed w-full h-full bg-amber-100 z-50 bg-transparent">
      <div className="bg-white h-full ml-auto w-[250px] pt-6 lg:order-1 flex flex-col items-end">
        <div className="cursor-pointer mr-5" onClick={toggleSidebar}>
          <img src="/images/closeIcon.svg" alt="x" />
        </div>
        <ul
          className="w-full flex items-center flex-col pt-10 pl-8 space-y-5 text-black font-p-bold text-[1.125rem]">
          <li className="border-b-2 lg:border-b-0 border-[#E5E8EC] pb-5 md:pb-0 w-full md:w-auto cursor-pointer"
              onClick={() => {
                toggleSidebar();
                scrollToSection(section2Ref);
              }}>Company
          </li>
          <li className="border-b-2 lg:border-b-0 border-[#E5E8EC] pb-5 md:pb-0 w-full md:w-auto cursor-pointer"
              onClick={() => {
                toggleSidebar();
                scrollToSection(section3Ref);
              }}>MyB
          </li>
          <li className="border-b-2 lg:border-b-0 border-[#E5E8EC] pb-5 md:pb-0 w-full md:w-auto cursor-pointer"
              onClick={() => {
                toggleSidebar();
                scrollToSection(section4Ref);
              }}>Partners
          </li>
        </ul>
      </div>
    </div>
  );
};

export { Sidebar };