import React from 'react';
import { Title } from './components/title';
import { Content } from './components/content';
import { Card } from './components/card';

const PrivacyPolicyFaceOff = () => {
  return <div className="max-w-[1140px] m-auto w-full px-6 py-10 lg:px-2 xl:px-0">
    <Title className="pt-6 pb-2">[필수] 개인정보 수집·이용 동의</Title>
    <Content>
      <Card className="flex flex-col justify-between text-black">
        <div className="py-2 flex justify-start w-full">
          <div className="py-2 flex-1"><li>개인정보항목</li></div>
          <div className="py-2 flex-1">이용자의 사진 (이용자가 직접 업로드한 사진)</div>
        </div>
        <div className="py-2 flex justify-start w-full">
          <div className="py-2 flex-1"><li>수집 ∙ 이용 목적</li></div>
          <div className="py-2 flex-1">개인 맞춤형 AI 이미지 생성</div>
        </div>
        <div className="py-2 flex justify-start w-full">
          <div className="py-2 flex-1"><li>보유기간</li></div>
          <div className="py-2 flex-1">AI 이미지 결과물 생성일로부터 1년 또는 이용자의 개인정보 삭제 요청시까지 (삭제 요청시 즉시 파기)</div>
        </div>
      </Card>
      <div className="pt-4 pb-2">귀하는 개인정보 수집∙이용에 동의를 거부할 수 있습니다. 다만, 동의를 거부할 경우, 회사가 제공하는 모바일 앱 서비스 중 개인 맞춤형 AI 이미지 생성 기능을 이용할 수 없습니다.</div>
    </Content>

    <Title className="pt-6 pb-2">[필수] 제 3자 개인정보 제공 동의</Title>
    <Content>
      <Card className="flex flex-col justify-between text-black">
        <div className="py-2 flex justify-start w-full">
          <div className="py-2 flex-1"><li>개인정보항목</li></div>
          <div className="py-2 flex-1">이용자의 사진, 성별</div>
        </div>
        <div className="py-2 flex justify-start w-full">
          <div className="py-2 flex-1"><li>수집 ∙ 이용 목적</li></div>
          <div className="py-2 flex-1">개인 맞춤형 AI 이미지 생성, 제공받는 기관의 AI 모델 학습</div>
        </div>
        <div className="py-2 flex justify-start w-full">
          <div className="py-2 flex-1"><li>보유기간</li></div>
          <div className="py-2 flex-1">1년 또는 동의 철회 시 (즉시 종료 및 파기)</div>
        </div>
        <div className="py-2 flex justify-start w-full">
          <div className="py-2 flex-1"><li>제공받는 기관</li></div>
          <div className="py-2 flex-1">주식회사 이너버즈</div>
        </div>
      </Card>
      <div className="pt-4 pb-2">귀하는 개인정보 제공 동의를 거부할 수 있습니다. 다만, 이에 동의하지 않는 경우에는 회사가 제공하는 모바일 앱 서비스 중 개인 맞춤형 AI 이미지 생성 기능을 이용할 수 없습니다.</div>
    </Content>
  </div>;
};

export { PrivacyPolicyFaceOff }