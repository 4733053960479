import React from 'react';

const Section2 = React.forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <div ref={ref} className="bg-[url('/public/images/company-bg-mob.webp')] sm:bg-[url('/public/images/company-bg.webp')] bg-bottom bg-cover bg-no-repeat">
      <div
        className="w-full max-w-[1148px] mx-auto pt-[65px] sm:pt-[5.625rem] pb-[10rem] sm:pb-[15rem] px-6 lg:px-2 xl:px-0">
        <div
          className="text-[1.625rem] sm:text-[2.5rem] text-white leading-9 mb-7 sm:mb-12 text-center sm:text-left font-p-bolder">Company
        </div>
        <div className="flex justify-between w-full flex-wrap">
          <div className="w-full lg:w-[50%] pr-2 xl:pr-5">
            <div className="w-full items-start flex flex-col mb-[2.5rem] lg:mb-[7rem] "><h3
              className="font-p-bold font-bold text-[1.25rem] tracking-wide text-white max-w-lg  leading-9 mb-2 hidden sm:block">1Self.World<span
              className="text-[#ccc] text-[1.125rem]">는</span> Web2.0<span
              className="text-[#ccc] text-[1.125rem]">과</span> Web 3.0<span
              className="text-[#ccc] text-[1.125rem]">을</span> 연결<span
              className="text-[#ccc] text-[1.125rem]">합니다.</span></h3><h3
              className="font-p-bold font-bold text-[1.125rem] text-white max-w-lg leading-7 sm:leading-9 mb-2 sm:hidden block">1Self.World<span
              className="text-[#ccc] text-[1.125rem]">는</span> <br />Web2.0<span
              className="text-[#ccc] text-[1.125rem]">과</span> Web 3.0<span
              className="text-[#ccc] text-[1.125rem]">을</span> 연결<span
              className="text-[#ccc] text-[1.125rem]">합니다.</span></h3><p
              className="font-medium text-[0.813rem] sm:text-[1rem] text-[#bdc9ec] max-w-[310px] sm:max-w-[450px] leading-5 sm:leading-7 break-keep">
              <span>지갑 기반의 리워드 앱으로 시작해, 블록체인 기술 기반의 자산관리,</span><span> 결제가 가능해지는 세상으로 나아갑니다.</span></p></div>
            <div className="flex flex-wrap lg:flex-nowrap w-full items-start mb-[1.875rem] lg:mb-0">
              <div
                className="items-center flex justify-center h-[50px] md:h-[100px] w-[80px] md:w-[100px] rounded-[10px] border-white border-solid border">
                <p
                  className="font-p-bold font-bold text-[0.875rem] sm:text-[1.125rem] text-white max-w-lg leading-5">Mission</p>
              </div>
              <div className="flex flex-col w-full md:w-[80%] pl-0 md:pl-5 pt-4 md:pt-0">
                <div className="w-full items-start flex flex-col"><h3
                  className="grid font-p-bold font-bold text-[1rem] text-white max-w-[290px] leading-6 sm:leading-7 mb-2">
                  <span>1Self.World는 Web3.0 기술을 활용하여,</span> <span>모바일 광고 시장을 혁신하고자 합니다.</span></h3><p
                  className="custom-block hidden md:grid text-[0.75rem] text-[#BFCEEC] max-w-[420px] leading-5 break-keep">
                  <span>지금까지 사용자는 데이터를 생산하지만, 데이터를 소유하지 못했습니다.</span><span className="custom-space"> 사용자가 생산한 데이터로 플랫폼 기업들은 막대한 광고 수익을 얻고 있습니다.</span><span
                  className="custom-space"> 사용자가 자신의 데이터를 소유하고, 데이터 기반의 수익을 공유 받을 수 있도록</span> <span
                  className="custom-space">혁신합니다.</span></p><p
                  className="grid md:hidden text-[0.74rem] text-[#BFCEEC] max-w-[420px] leading-5 break-keep">
                  <span>지금까지 사용자는 데이터를 생산하지만, 데이터를 소유하지 </span><span>못했습니다. 사용자가 생산한 데이터로 플랫폼 기업들은 막대한 </span><span>광고 수익을 얻고 있습니다. </span><span>사용자가 자신의 데이터를 소유하고, 데이터 기반의 수익을 </span><span>공유 받을 수 있도록 혁신합니다. </span>
                </p></div>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-[50%]">
            <div className="flex flex-wrap lg:flex-nowrap w-full items-start mb-[0.875rem] md:mb-[2.875rem]">
              <div
                className="items-center flex justify-center h-[50px] md:h-[100px] w-[80px] md:w-[100px] rounded-[10px] border-white border-solid border">
                <p
                  className="font-p-bold font-bold text-[0.875rem] sm:text-[1.125rem] text-white max-w-lg leading-55">Vision</p>
              </div>
              <div className="flex flex-col w-full md:w-[80%] pl-0 md:pl-5 pt-4 md:pt-0">
                <div className="flex flex-wrap sm:flex-nowrap flex-col sm:flex-row justify-start text-left mb-4"><p
                  className="text-[1rem] text-white font-p-bold font-bold w-[70px] mb-1 sm:mb-0">Web1.0</p>
                  <p
                    className="grid text-[0.75rem] text-[#BFCEEC] w-full sm:w-[81%] leading-[20px] break-keep">
                    <span>아이디와 비밀번호를 활용해 웹사이트를 이용했습니다.</span><span>불편한 결제 모듈을 사용했습니다.</span></p></div>
                <div className="flex flex-wrap sm:flex-nowrap flex-col sm:flex-row justify-start text-left mb-4"><p
                  className="text-[1rem] text-white font-p-bold font-bold w-[70px] mb-1 sm:mb-0">Web2.0</p>
                  <p
                    className="custom-block hidden sm:grid text-[0.75rem] text-[#BFCEEC] w-full sm:w-[83%] xl:w-[81%] leading-[20px] break-keep">
                    <span>메신저, 소셜 미디어 기반 간편로그인을 활용해 앱 서비스를 이용합니다.</span><span
                    className="custom-space">금융 앱이 필요합니다.</span></p><p
                    className="grid sm:hidden  text-[0.75rem] text-[#BFCEEC] w-full sm:w-[81%] leading-[20px] break-keep">
                    <span>메신저, 소셜 미디어 기반 간편로그인을 활용해 앱 서비스를</span><span>이용합니다. 금융 앱이 필요합니다.</span></p></div>
                <div className="flex flex-wrap sm:flex-nowrap flex-col sm:flex-row justify-start text-left mb-4"><p
                  className="text-[1rem] text-white font-p-bold font-bold w-[70px] mb-1 sm:mb-0">Web3.0</p>
                  <p
                    className="grid text-[0.75rem] text-[#BFCEEC] w-full sm:w-[81%] leading-[20px] break-keep">
                    <span>시대는 지갑 기반으로 로그인과 결제가 통합된 서비스 이용이</span><span>가능해 집니다.</span></p></div>
              </div>
            </div>
            <div className="flex flex-wrap lg:flex-nowrap w-full items-start">
              <div
                className="items-center flex justify-center h-[50px] md:h-[100px] w-[80px] md:w-[100px] rounded-[10px] border-white border-solid border">
                <p
                  className="font-p-bold font-bold text-[0.875rem] sm:text-[1.125rem] text-white max-w-lg leading-5">Value</p>
              </div>
              <div className="flex flex-col w-full md:w-[80%] pl-0 md:pl-5 pt-4 md:pt-0">
                <div className="w-full items-start flex flex-col"><h3
                  className="hidden sm:grid font-p-bold font-bold text-[1rem] text-white max-w-[320px] sm:max-w-[300px] leading-6 sm:leading-7 mb-2 break-keep">
                  <span> 1Self.World는 Web 2.0과 Web3.0의</span> <span>경험과 전문성을 가진 사람들이 모여있습니다.</span></h3><h3
                  className="grid sm:hidden font-p-bold font-bold text-[1rem] text-white max-w-[320px] sm:max-w-[300px] leading-6 sm:leading-7 mb-2 break-keep">
                  <span>1Self.World는 Web 2.0과 Web3.0의 경험과</span> <span>전문성을 가진 사람들이 모여있습니다.</span></h3><p
                  className="custom-block hidden md:grid text-[0.75rem] text-[#BFCEEC] max-w-[420px] leading-5 break-keep">
                  <span>현실의 문제를 해결하는 실질적이고 지속가능한 가치를 창출합니다.</span><span className="custom-space"> 규제를 준수하고, Web 3.0의 이상과 철학 즉, 탈중앙화와 독립된 개인을 지지합니다. </span>
                  <span className="custom-space">수준 높은 제품과 재미와 의미가 있는 서비스를 제공합니다. </span><span className="custom-space"> 다양성을 인정하고 투명하고 합리적으로 운영합니다.</span>
                </p><p
                  className="grid md:hidden text-[0.75rem] lg:text-[0.75rem] text-[#BFCEEC] max-w-[420px] leading-5 break-keep">
                  <span>현실의 문제를 해결하는 실질적이고 지속가능한 가치를 창출 </span><span>합니다. 규제를 준수하고, Web 3.0의 이상과 철학 </span><span>즉, 탈중앙화와 독립된 개인을 지지합니다. 수준 높은 제품과 </span><span>재미와 의미가 있는 서비스를 제공합니다. 다양성을 인정하고 </span><span>투명하고 합리적으로 운영합니다. </span>
                </p></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export { Section2 };
