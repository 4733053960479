import React from 'react';

type HeaderProps = {
  section2Ref: React.RefObject<HTMLDivElement>;
  section3Ref: React.RefObject<HTMLDivElement>;
  section4Ref: React.RefObject<HTMLDivElement>;
  toggleSidebar: () => void;
  scrollToSection: (ref: React.RefObject<HTMLDivElement>) => void;
};

const Header: React.FC<HeaderProps> = ({ section2Ref, section3Ref, section4Ref, toggleSidebar, scrollToSection }) => {
  return (
    <div className="w-full bg-[rgba(0,0,0,0.92)] h-[76px] fixed flex justify-center items-center">
      <div className="w-full flex justify-between items-center max-w-[1140px] px-10">
        <div>
          <img src="/images/logo.svg" alt="logo" className="w-[156px]" />
        </div>

        <div className="lg:flex hidden text-white justify-center items-center text-[18px] font-p-bold gap-10">
          <div className="cursor-pointer" onClick={() => scrollToSection(section2Ref)}>Company</div>
          <div className="cursor-pointer" onClick={() => scrollToSection(section3Ref)}>MyB</div>
          <div className="cursor-pointer" onClick={() => scrollToSection(section4Ref)}>Partners</div>
        </div>

        <div className="lg:hidden block cursor-pointer" onClick={toggleSidebar}>
          <svg className="w-10 h-10" fill="#fff" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"></path>
          </svg>
        </div>
      </div>
    </div>
  );
};

export { Header };
