import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Main from './Main';
import { TermConditions } from './term-conditions';
import { PrivacyPolicy } from './privacy-policy';
import { PrivacyPolicyFaceOff } from './privacy-policy-face-off';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/term-conditions" element={<TermConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/privacy-policy-face-off" element={<PrivacyPolicyFaceOff />} />
      </Routes>
    </Router>
  );
};

export default App;
