import React, { useEffect, useRef, useState } from 'react';

import { Header } from './components/header';
import { Footer } from './components/footer';
import { Section1 } from './components/section-1';
import { Section2 } from './components/section-2';
import { Section3 } from './components/section-3';
import { Section4 } from './components/section-4';
import { Section5 } from './components/section-5';
import { Sidebar } from './components/Sidebar';

function App() {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);

  const section2Ref = useRef<HTMLDivElement>(null);
  const section3Ref = useRef<HTMLDivElement>(null);
  const section4Ref = useRef<HTMLDivElement>(null);
  const section5Ref = useRef<HTMLDivElement>(null);

  const toggleSidebar = () => {
    setSidebarOpen((prev) => !prev);
  };
  const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setSidebarOpen(false);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      {sidebarOpen && (
        <Sidebar
          toggleSidebar={toggleSidebar}
          scrollToSection={scrollToSection}
          section2Ref={section2Ref}
          section3Ref={section3Ref}
          section4Ref={section4Ref}
        />
      )}
      <Header
        section2Ref={section2Ref}
        section3Ref={section3Ref}
        section4Ref={section4Ref}
        toggleSidebar={toggleSidebar}
        scrollToSection={scrollToSection}
      />

      <Section1 />
      <Section2 ref={section2Ref} />
      <Section3 ref={section3Ref} />
      <Section4 ref={section4Ref} />
      {/* <Section5 ref={section5Ref} /> */}
      <Footer />
    </div>
  );
}

export default App;
