import React from 'react';

const Content: React.FC<{ children: React.ReactNode; className?: string }> = ({ children, className }) => {
  return (
    <div
      className={`font-p-thin text-[0.875rem] leading-[22px] text-[rgb(92,101,115)] ${className || ''}`}
    >
      {children}
    </div>
  );
};

export { Content };
