import React from 'react';
import { Title } from './components/title';
import { Content } from './components/content';
import { OrderedList } from './components/ordered-list';
import { Card } from './components/card';

const PrivacyPolicy = () => {
  return <div className="max-w-[1140px] m-auto w-full px-6 py-10 lg:px-2 xl:px-0">
    <Content>원셀프월드 주식회사(이하 "회사")는 MyB (이하 “서비스”)를 제공함에 있어 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「개인정보 보호법」, 「통신비밀보호법」 등 국내의 개인정보보호 관련법령을 준수하며, 이용자 권익 보호에 최선을 다하고 있습니다. 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.</Content>

    <Title className="pt-6 pb-2">1. 처리하는 개인정보</Title>
    <Content>
      <div className="mt-[30px]">(1) 회사는 서비스 이용에 필요한 최소한의 개인정보를 수집하며, 이용자에게 해당 사실을 알리고 사전 동의를 받습니다. 회사가 이용자로부터 처리하는 개인정보는 아래와 같습니다.</div>
      <Card className="flex justify-between text-black">
        <div className="py-2 flex flex-col justify-start w-full">
          <div className="py-2"><li>처리시점 및 목적</li></div>
          <div className="py-2"><li>필수/선택</li></div>
          <div className="py-2"><li>처리항목</li></div>
        </div>
        <div className="py-2 flex flex-col justify-start w-full">
          <div className="py-2">서비스 설치</div>
          <div className="py-2">필수</div>
          <div className="py-2">PIN code, Wallet Address</div>
        </div>
      </Card>
      <Card className="flex justify-between text-black">
        <div className="py-2 flex flex-col justify-start w-full">
          <div className="py-2"><li>처리시점 및 목적</li></div>
          <div className="py-2"><li>필수/선택</li></div>
          <div className="py-2"><li>처리항목</li></div>
        </div>
        <div className="py-2 flex flex-col justify-start w-full">
          <div className="py-2">서비스 설치</div>
          <div className="py-2">필수</div>
          <div className="py-2">광고식별자(ADID, IDFA)</div>
        </div>
      </Card>
      <Card className="flex justify-between text-black">
        <div className="py-2 flex flex-col justify-start w-full">
          <div className="py-2"><li>처리시점 및 목적</li></div>
          <div className="py-2"><li>필수/선택</li></div>
          <div className="py-2"><li>처리항목</li></div>
        </div>
        <div className="py-2 flex flex-col justify-start w-full">
          <div className="py-2">본인 확인</div>
          <div className="py-2">선택</div>
          <div className="py-2">CI(암호화), 성별, 생년월</div>
        </div>
      </Card>
      <Card className="flex justify-between text-black">
        <div className="py-2 flex flex-col justify-start w-full">
          <div className="py-2"><li>처리시점 및 목적</li></div>
          <div className="py-2"><li>필수/선택</li></div>
          <div className="py-2"><li>처리항목</li></div>
        </div>
        <div className="py-2 flex flex-col justify-start w-full">
          <div className="py-2">개인 맞춤형 AI 이미지 생성</div>
          <div className="py-2">선택</div>
          <div className="py-2">이용자가 직접 업로드한 사진</div>
        </div>
      </Card>
      <Card className="flex justify-between text-black">
        <div className="py-2 flex flex-col justify-start w-full">
          <div className="py-2"><li>처리시점 및 목적</li></div>
          <div className="py-2"><li>필수/선택</li></div>
          <div className="py-2"><li>처리항목</li></div>
        </div>
        <div className="py-2 flex flex-col justify-start w-full">
          <div className="py-2">설치 앱 인증 이벤트</div>
          <div className="py-2">선택</div>
          <div className="py-2">기기 내 설치 앱 목록</div>
        </div>
      </Card>
      <Card className="flex justify-between text-black">
        <div className="py-2 flex flex-col justify-start w-full">
          <div className="py-2"><li>처리시점 및 목적</li></div>
          <div className="py-2"><li>필수/선택</li></div>
          <div className="py-2"><li>처리항목</li></div>
        </div>
        <div className="py-2 flex flex-col justify-start w-full">
          <div className="py-2">설치 앱 인증 이벤트</div>
          <div className="py-2">선택</div>
          <div className="py-2">기기 내 설치 앱 목록</div>
        </div>
      </Card>
      <div className="mt-[30px]">(2) 서비스 이용 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.</div>
      <Card className="font-[0.813rem] px-[24px]">
        IP, 방문일시, 서비스 이용기록, 브라우저정보, 광고식별자(ADID/IDFA), 기기정보(모바일 OS, ICCID, IMSI, IMEI), 앱설치내역 등 서비스 이용 과정에서 자동으로 생성되어 수집되는 정보
      </Card>
      <div className="mt-[30px]">(3) 회사는 다음의 방법을 통해 개인정보를 수집합니다.</div>
      <OrderedList type="a">
        <li>서비스 가입 및 서비스 이용 과정에서 이용자가 개인정보 수집에 대해 동의를 하고, 직접 정보를 입력하거나 제공하는 경우 해당 개인정보를 수집합니다.</li>
        <li>고객센터를 통해 상담 과정에서 웹페이지, 메일, 팩스, 전화 등을 통해 이용자의 개인정보가 수집될 수 있습니다.</li>
        <li>회사와 제휴한 외부 기업으로부터 개인정보를 제공받을 수 있으며, 이 경우 제휴사가 이용자에게 개인정보 제공 동의를 받은 후에 회사에 제공합니다.</li>
        <li>자동생성정보는 생성정보 수집 툴을 통해 수집합니다.</li>
      </OrderedList>
    </Content>

    <Title className="pt-6 pb-2">2. 개인정보 처리 목적</Title>
    <Content>
      <div className="pt-4 pb-2">회사는 수집한 이용자의 개인정보를 아래의 목적으로만 이용하며, 이용 목적이 변경된 경우에는 사전에 이용자에게 알린 후 동의를 받습니다.</div>
      <OrderedList type="a">
        <li>이용자 식별</li>
        <li>서비스 제공, 문의사항 또는 불만처리, 공지사항 전달, 민원서비스 분석 및 개선</li>
        <li>서비스 이용기록과 접속빈도 분석, 어플리케이션 접속이용 유지</li>
        <li>신규 서비스 출시, 서비스 이용에 대한 통계, 서비스 개선</li>
        <li>법령에 따라 수사기관의 범죄 수사와 공소의 제기 및 유지에 협조하기 위하여 필요한 경우</li>
      </OrderedList>
    </Content>

    <Title className="pt-6 pb-2">3. 개인정보 제 3 자 제공</Title>
    <Content>
      <div className="pt-4 pb-2">회사는 이용자의 동의 없이 개인정보를 외부에 제공하지 않습니다. 하지만 이용자의 서비스 이용에 필요한 경우 최소한의 개인정보에 대해 이용자의 사전 동의나 관련 법령의 규정 등에 근거하여 이용자의 개인정보를 제3자에게 제공할 수 있습니다. 회사는 제3자에게 개인정보를 제공하는 경우 개인정보 처리방침에 해당 내용을 게재합니다.</div>
      <div className="pt-4 pb-2">[정보제공 기관 및 처리업무]</div>
      <Card className="flex flex-col justify-between text-black">
        <div className="py-2 flex justify-start w-full">
          <div className="py-2 flex-1"><li>수탁업체</li></div>
          <div className="py-2 flex-1">주식회사 버즈빌</div>
        </div>
        <div className="py-2 flex justify-start w-full">
          <div className="py-2 flex-1"><li>정보제공 업무 내용</li></div>
          <div className="py-2 flex-1">광고식별정보의 보관</div>
        </div>
        <div className="py-2 flex justify-start w-full">
          <div className="py-2 flex-1"><li>정보제공 항목</li></div>
          <div className="py-2 flex-1">성별, 생년월</div>
        </div>
        <div className="py-2 flex justify-start w-full">
          <div className="py-2 flex-1"><li>개인정보 보유 및 이용기간</li></div>
          <div className="py-2 flex-1">1년 또는 동의철회 시</div>
        </div>
      </Card>
      <Card className="flex flex-col justify-between text-black">
        <div className="py-2 flex justify-start w-full">
          <div className="py-2 flex-1"><li>수탁업체</li></div>
          <div className="py-2 flex-1">주식회사 이너버즈</div>
        </div>
        <div className="py-2 flex justify-start w-full">
          <div className="py-2 flex-1"><li>정보제공 업무 내용</li></div>
          <div className="py-2 flex-1">개인 맞춤형 AI 이미지 생성, 제공받는 기관의 AI 모델 학습</div>
        </div>
        <div className="py-2 flex justify-start w-full">
          <div className="py-2 flex-1"><li>정보제공 항목</li></div>
          <div className="py-2 flex-1">이용자가 직접 업로드한 사진, 성별</div>
        </div>
        <div className="py-2 flex justify-start w-full">
          <div className="py-2 flex-1"><li>개인정보 보유 및 이용기간</li></div>
          <div className="py-2 flex-1">1년 또는 동의철회 시</div>
        </div>
      </Card>
    </Content>

    <Title className="pt-6 pb-2">4. 개인정보처리 위탁</Title>
    <Content>
      <div className="pt-4 pb-2">회사는 이용자에게 서비스를 제공하기 위해 아래와 같이 개인정보 및 처리업무를 외부 제휴기관에 위탁하고 있습니다. 회사는 위탁 받은 업체가 개인정보보호 관련 법령을 위반하지 않도록 관리·감독하고 있습니다.</div>
      <div className="pt-4 pb-2">[위탁 기관 및 처리업무]</div>
      <Card className="flex justify-between text-black">
        <div className="py-2 flex flex-col justify-start w-full">
          <div className="py-2"><li>수탁업체</li></div>
          <div className="py-2"><li>위탁 업무 내용</li></div>
          <div className="py-2"><li>위탁 항목</li></div>
          <div className="py-2"><li>개인정보 보유 및 이용기간</li></div>
        </div>
        <div className="py-2 flex flex-col justify-start w-full">
          <div className="py-2">주식회사 사각</div>
          <div className="py-2">니모닉의 Cloud DB 보관</div>
          <div className="py-2">니모닉</div>
          <div className="py-2">위탁 업무 완료 또는 위탁관계 종료시까지</div>
        </div>
      </Card>
    </Content>

    <Title className="pt-6 pb-2">5. 보관/제거</Title>
    <Content>
      <div className="pt-4 pb-2">계정을 일시적으로 비활성화하거나 계정 및 데이터를 영구적으로 삭제하려면 'support@1self.world'에 문의하세요.</div>
      <div className="pt-4 pb-2">모바일 앱에서도 계정을 삭제할 수 있습니다. 단계는 다음과 같습니다.</div>
      <img src="/images/delete-img.png" alt="delete-image" width={320} height={320} />
      <img src="/images/delete-img-account.png" alt="delete-image-account" width={320} height={320} />
      <div className="pt-4 pb-2">개인 데이터가 필요한 기간 이상 보관되지 않도록 MyB 보관 정책을 가지고 있으며 개인 데이터는 매년 처리 및 검토됩니다.</div>
      <div className="pt-4 pb-2">보관 정책은 어떤 데이터를 보관해야 하는지, 얼마 동안 보관해야 하는지, 왜 보관해야 하는지를 고려해야 합니다.</div>
    </Content>

    <Title className="pt-6 pb-2">6. 개인정보의 처리 및 보유 기간</Title>
    <Content>
      <div className="pt-4 pb-2">(1) 회사는 법령에 따른 개인정보 처리 및 보유 기간 또는 정보주체로부터 개인정보를 수집할 때 동의 받은 개인정보 처리 및 보유기간 내에서 개인정보를
        보유합니다.
      </div>
      <Card className="flex flex-col justify-between text-black">
        <div className="py-2 flex justify-start w-full">
          <div className="py-2 flex-1"><li>개인정보</li></div>
          <div className="py-2 flex-1">서비스 이용 관련 기록</div>
        </div>
        <div className="py-2 flex justify-start w-full">
          <div className="py-2 flex-1"><li>보존근거</li></div>
          <div className="py-2 flex-1">통신비밀보호법 제15조의 2 동법 시행령 제41조 제2항 제2호</div>
        </div>
        <div className="py-2 flex justify-start w-full">
          <div className="py-2 flex-1"><li>보존기간</li></div>
          <div className="py-2 flex-1">3개월</div>
        </div>
      </Card>
      <Card className="flex flex-col justify-between text-black">
        <div className="py-2 flex justify-start w-full">
          <div className="py-2 flex-1"><li>개인정보</li></div>
          <div className="py-2 flex-1">소비자의 불만 또는 분쟁처리에 관한 기록</div>
        </div>
        <div className="py-2 flex justify-start w-full">
          <div className="py-2 flex-1"><li>보존근거</li></div>
          <div className="py-2 flex-1">전자상거래 등에서의 소비자보호에 관한 법률 제6조 제3항 동법 시행령 제6조 제1항 제4호</div>
        </div>
        <div className="py-2 flex justify-start w-full">
          <div className="py-2 flex-1"><li>보존기간</li></div>
          <div className="py-2 flex-1">5년</div>
        </div>
      </Card>
      <div className="pt-4 pb-2">(2) 회사는 보유기간의 경과, 개인정보의 처리 목적 달성 등 그 개인정보가 불필요하게 되었을 때에는 해당 정보를 지체없이 파기합니다. 종이에 출력된 개인정보는 분쇄기로 분쇄 또는 소각하여 파 기하고, 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.</div>
      <div className="pt-4 pb-2">(3) 회사는 개인정보의 파기 30 일 전까지 개인정보가 파기되는 사실, 기간 만료일 및 파기되는 개인정보의 항목을, 회사가 가능한 방법으로 알립니다.</div>
    </Content>

    <Title className="pt-6 pb-2">7. 이용자의 권리 및 그 행사방법</Title>
    <Content>
      <OrderedList type="a">
        <li>이용자 및 이용자의 법정대리인은 언제든지 사이트에 등록되어 있는 자신의 개인정보를 조회하거나 수정할 수 있습니다. 정보주체의 법정대리인이나 위임을 받은 자 등 대리인이 권리행사를 하는 경우 ‘개인정보 처리 방법에 관한 고시(제2020-7호)’ 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.</li>
        <li>이용자는 언제든지 개인정보 제공에 관한 동의 철회 또는 서비스 가입 가입해지 요청을 할 수 있습니다. 동의철회 또는 가입 해지를 원하시는 경우, 서비스 내 게시된 개인정보 삭제요청 이메일을 통해 요청의사를 전달하면 절차를 거친 후 진행할 수 있습니다. 다만, 가입해지를 하신 경우 서비스의 일부 또는 전부의 이용이 어려울 수 있습니다.</li>
        <li>이용자는 사이트에 등록되어 있는 이용자의 개인정보가 사실과 다른 경우 회사에 정정 또는 삭제를 청구할 수 있습니다. 회사는 이용자가 개인정보의 오류에 대한 정정을 요청한 경우 정정을 완료하기 전까지 당해 개인정보를 서비스에 이용하거나 또는 제3 자에게 제공하지 않습니다. 또한 회사는 잘못된 개인정보를 제3 자에게 이미 제공한 경우 정정 처리결과를 제 3 자에게 지체 없이 통지하여 정정이 이루어지도록 합니다.</li>
        <li>개인정보 열람, 처리정지 및 동의 철회의 경우 「개인정보 보호법」 제35조 제4항, 제37조 제2항 및 제3항에 의하여 정보주체의 권리가 제한될 수 있고 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 보관 대상으로 명시되어 있는 경우 그 삭제를 요구할 수 없습니다.</li>
        <li>회사는 이용자의 요청으로 해지 또는 삭제한 개인정보를 5. 개인정보의 처리 및 보유기간에 명시된 바에 따라 관리, 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 조치하고 있습니다.</li>
      </OrderedList>
    </Content>

    <Title className="pt-6 pb-2">8. 개인정보의 기술적/물리적/관리적 보호 대책</Title>
    <Content>
      <div className="pt-4 pb-2">회사는 이용자의 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 하는 등 안전성 확보를 위하여 다음과 같은 기술적/물리적/관리적 대책을 강구하고 있습니다.</div>
      <OrderedList type="a">
        <li>개인정보의 안전한 처리를 위하여 담당 직원에 대한 수시 교육을 진행하고 있습니다.</li>
        <li>개인정보에 대한 접근 통제 및 접근 권한을 제한하기 위하여 이용자의 개인정보를 처리하는 담당인원을 최소한으로 제한하며, 관련 직원에 변동이 있을 경우, 지체없이 접근권한을 변경 또는 말소하고 있습니다.</li>
        <li>개인정보를 안전하게 저장·전송할 수 있도록 이용자의 개인정보는 비밀번호에 의해 보호되며, 파일 및 전송 데이터를 암호화하여 중요한 데이터는 암호화통신(SSL)등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하는 등 노력을 기울이고 있습니다.</li>
        <li>개인정보 침해사고 발생에 대응하기 위한 접속기록의 보관 및 위조·변조 방지를 위하여 개인정보취급자가 개인정보처리시스템에 접속하여 개인정보를 처리한 경우 접속일시, 처리내역 등을 저장하고 위·변조 또는 도난, 분실, 파기되지 않도록 별도로 보관합니다.</li>
        <li>회사는 컴퓨터 바이러스에 의한 개인정보 피해가 발생하지 않도록 백신프로그램을 이용하고 있으며, 주기적으로 업데이트를 진행하는 등 개인정보에 대한 보안프로그램을 설치 및 갱신하고 있습니다.</li>
        <li>개인정보의 안전한 보관을 위하여 외부침입을 차단하는 보안장치를 이용하고 있으며, 침입탐지시스템을 설치하여 불법적인 침입을 감시하는 등 별도의 보관시설의 마련 또는 잠금 장치의 설치 등 물리적 조치를 취하고 있습니다.</li>
      </OrderedList>
    </Content>

    <Title className="pt-6 pb-2">9. 행태정보의 수집ㆍ이용 및 거부 등에 관한 사항</Title>
    <Content>
      <OrderedList type="a">
        <li>회사는 이용자의 동의를 얻어 모바일 앱에서 최적화된 서비스 및 혜택, 맞춤형 광고 등을 제공하기 위하여 ADID(안드로이드), IDFA(아이폰)를 수집·이용할 수 있습니다.</li>
        <li>회사는 온라인 맞춤형 광고 등에 필요한 최소한의 행태정보만을 수집하며, 사상, 신념, 가족 및 친인척관계, 학력, 병력, 기타 사회활동 경력 등 개인의 권리나 사생활을 침해할 우려가 있는 민감한 행태정보를 수집하지 않습니다.</li>
        <li>이용자는 모바일 단말기의 설정 변경을 통해 앱의 맞춤형 광고를 차단·허용할 수 있습니다.</li>
        <OrderedList type="i">
          [스마트폰의 광고식별자 차단/허용]
          <li>안드로이드 : ① 설정 → ② 개인정보보호 → ③ 광고 → ③ 광고 ID 재설정 또는 광고 ID 삭제</li>
          <li>아이폰) : ① 설정 → ② 개인정보보호 → ③ 추적 → ④ 앱이 추적을 요청하도록 허용 끔</li>
          <li>모바일 OS 버전에 따라 메뉴 및 방법이 다소 상이할 수 있습니다.</li>
        </OrderedList>
        <li>정보주체는 ’12. 개인정보보호책임자 및 담당부서의 연락처’에 기재된 연락처로 행태정보와 관련하여 궁금한 사항과 거부권 행사, 피해 신고 접수 등의 문의할 수 있습니다.</li>
      </OrderedList>
    </Content>

    <Title className="pt-6 pb-2">10. 추가적인 이용ㆍ제공 판단기준</Title>
    <Content>
      <OrderedList type="a">
        <li>회사는 「개인정보 보호법」 제15조 제3항 및 제17조 제4항에 따라 「개인정보 보호법」 시행령 제14조의2에 따른 사항을 고려하여 정보주체의 동의 없이 개인정보를 추가적으로 이용·제공할 수 있습니다.</li>
        <li>회사는 정보주체의 동의 없이 추가적인 이용·제공을 하기 위해서 다음과 같은 사항을 고려합니다.</li>
        <OrderedList type="i">
          <li>개인정보를 추가적으로 이용·제공하려는 목적이 당초 수집 목적과 관련성이 있는지 여부</li>
          <li>개인정보를 수집한 정황 또는 처리 관행에 비추어 볼 때 추가적인 이용·제공에 대한 예측 가능성이 있는지 여부</li>
          <li>개인정보의 추가적인 이용·제공이 정보주체의 이익을 부당하게 침해하는지 여부</li>
          <li>가명처리 또는 암호화 등 안전성 확보에 필요한 조치를 하였는지 여부</li>
        </OrderedList>
        <li>정보주체는 ’12. 개인정보보호책임자 및 담당부서의 연락처’에 기재된 연락처로 행태정보와 관련하여 궁금한 사항과 거부권 행사, 피해 신고 접수 등의 문의할 수 있습니다.</li>
      </OrderedList>
    </Content>

    <Title className="pt-6 pb-2">11. 개인정보처리방침의 적용 제외</Title>
    <Content>
      <div className="pt-4 pb-2">회사는 서비스를 통하여 이용자에게 다른 회사의 웹사이트 또는 자료에 대한 링크를 제공할 수 있습니다. 이 경우 회사는 외부사이트 및 자료에 대하여 통제권이 없을 뿐만 아니라 이들이 개인정보를 수집하는 행위에 대하여 회사의 '개인정보처리방침'이 적용되지 않습니다. 따라서, 회사가 포함하고 있는 링크를 클릭하여 타 사이트의 페이지로 이동할 경우에는 새로 방문한 사이트의 개인정보처리방침을 반드시 확인하시기 바랍니다.</div>
    </Content>

    <Title className="pt-6 pb-2">12. 개인정보보호책임자 및 담당부서의 연락처</Title>
    <Content>
      <div className="pt-4 pb-2">회사는 이용자의 개인정보가 훼손되거나 침해되지 않도록 최선을 다하고 있으며, 아래와 같이 개인정보의 처리에 관한 업무를 총괄해서 책임질 개인정보 보호책임자 및 개인정보 보호법무 관련 고충사항과 개인정보의 열람청구를 접수 및 처리하는 부서를 두고 있습니다. 다만, 회사는 개인정보 보호를 위해 법률상 요구되는 기술적, 물리적, 관리적 조치를 다하였음에도 불구하고, 이용자 본인의 부주의나 회사가 관리하지 않는 영역에서의 사고 등 회사의 귀책에 기인하지 않은 손해에 대해서는 책임을 지지 않습니다.</div>
      <OrderedList type="a">
        <li>개인정보보호 책임자 : 이종욱</li>
        <li>담당부서(고객센터) 연락처</li>
        <OrderedList type="i">
          <li>Email: contact@1self.world</li>
        </OrderedList>
        <li>개인정보침해에 대한 신고나 상담이 필요한 경우에는 아래 기관에 문의하시기 바랍니다.</li>
        <OrderedList type="i">
          <li>개인정보분쟁조정위원회: http://www.kopico.go.kr, 국번없이 1833-6972</li>
          <li>개인정보침해신고센터: http://privacy.kisa.or.kr, 국번없이 118</li>
          <li>대검찰청 사이버수사과: www.spo.go.kr, 국번없이 1301, 02-3480-2000</li>
          <li>경찰청 사이버안전국: www.police.go.kr, 국번없이 182</li>
        </OrderedList>
      </OrderedList>
    </Content>

    <Title className="pt-6 pb-2">13. 고지의 의무</Title>
    <Content>
      <div className="py-2">이 개인정보 처리방침은 시행일로부터 적용됩니다. 회사는 본 개인정보 처리방침을 변경하는 경우에는 변경 및 시행의 시기, 변경된 내용을 홈페이지의 팝업(또는 개별공지)을 통해 고지할 것입니다.</div>
    </Content>

    <Title className="pt-6 pb-2">
      <div>시행일자: 2024년 04월 01일</div>
      <div>개정일자: 2024년 11월 19일</div>
    </Title>
  </div>;
};

export { PrivacyPolicy }